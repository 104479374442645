export default function useMainComponentProvide() {
  let mainComponentName = ''
  const mainComponent = ref('')
  const init = (name) => {
    if (!process.server) {
      // eslint-disable-next-line no-underscore-dangle
      mainComponentName = getCurrentInstance().type.__name
      mainComponent.value = mainComponentName

      const addHeadline = function (hl) {
        mainComponent.value = mainComponentName + '_' + hl
      }

      provide('mainComponent', {
        mainComponent,
        addHeadline
      })
    }
  }

  return {
    init
  }
}
